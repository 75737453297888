import React from 'react';

import {NOTIFICATION_SHOW_TIME} from 'client/scenes/notifications/constants';

interface UseNotificationAutoCloseOptions {
    disabled?: boolean;
}

interface UseNotificationAutoCloseResult {
    isClosing: boolean;
}

export const useNotificationAutoClose = ({
    disabled
}: UseNotificationAutoCloseOptions = {}): UseNotificationAutoCloseResult => {
    const [isClosing, setClosing] = React.useState(false);

    React.useEffect(() => {
        if (disabled) {
            return;
        }

        const showTimeout = window.setTimeout(() => {
            setClosing(true);
        }, NOTIFICATION_SHOW_TIME);

        return () => {
            window.clearTimeout(showTimeout);
        };
    }, [disabled, setClosing]);

    return {isClosing};
};
