export enum MapQueryParams {
    CODE = 'code',
    REDIRECT_URI = 'redirect_uri',
    STATE = 'state',
    USER_KIND = 'user_kind',
    STATUS = 'status',
    PARTNER_ID_GLOBAL = 'partner_id_global',

    ERROR = 'error',
    ERROR_TITLE = 'error_title',
    ERROR_DESCRIPTION = 'error_description',
    RETURN_URL = 'return_url',
    RELAY_STATE = 'RelayState',
    SAML_RESPONSE = 'SAMLResponse'
}
