import {useQuery, UseQueryOptions} from 'react-query';

import {ROUTES} from '@/src/constants/routes';
import type {UsersImportEntity} from '@/src/entities/users-import/entity';
import type {ApiResponseError} from '@/src/errors';
import {usersClient} from 'client/api/client';

const {GET_LAST_IMPORT_FOR_USER} = ROUTES.API.V1.PARTNER_OR_COMPANY.USERS;

export function useGetLastUsersImport(options?: UseQueryOptions<UsersImportEntity | undefined, ApiResponseError>) {
    return useQuery<UsersImportEntity | undefined, ApiResponseError>(
        [GET_LAST_IMPORT_FOR_USER],
        () => usersClient.getLastUsersImport(),
        options
    );
}
