import * as B2bIdmTypes from '@lavka-birds/api-clients/dist/clients/b2b-idm-api/types';
import {IdentityBasketUserKind} from '@lavka-birds/b2b-common';
import {
    any,
    array,
    boolean,
    coerce,
    enums,
    Infer,
    lazy,
    nullable,
    number,
    optional,
    record,
    string,
    Struct,
    type,
    union
} from 'superstruct';

const {SortDirection, GetUserRolesSortBy} = B2bIdmTypes;

export const IdmUserRoleService = B2bIdmTypes.Service;
export type IdmUserRoleService = B2bIdmTypes.Service;

export const IdmUserRoleStatus = B2bIdmTypes.UserRoleStatus;
export type IdmUserRoleStatus = B2bIdmTypes.UserRoleStatus;

export const IdmOrganizationLevel = B2bIdmTypes.OrganizationLevel;
export type IdmOrganizationLevel = B2bIdmTypes.OrganizationLevel;

export const IdmUserKind = B2bIdmTypes.UserKind;
export type IdmUserKind = B2bIdmTypes.UserKind;

export const IdmRoleNodeType = B2bIdmTypes.RoleNodeType;
export type IdmRoleNodeType = B2bIdmTypes.RoleNodeType;

export const IdmBulkChangeRolesAction = B2bIdmTypes.BulkChangeRolesAction;
export type IdmBulkChangeRolesAction = B2bIdmTypes.BulkChangeRolesAction;

export type BulkChangeRoleFieldItem = B2bIdmTypes.BulkChangeRoleFieldItem;

export type ChangeRoleFieldsValues = Record<string, BulkChangeRoleFieldItem[]>;

export type IdmUser = B2bIdmTypes.UserWithPersonalRoles;

export const serviceStruct = enums(Object.values(IdmUserRoleService));

export const organizationLevelStruct = enums(Object.values(IdmOrganizationLevel));

export const GetIdmRolesBodyStruct = type({
    ids: optional(array(number())),
    services: optional(array(serviceStruct)),
    path: optional(string()),
    searchText: optional(string()),
    lang: optional(string()),
    companyIds: optional(array(string())),
    userRoleResourceCompanyIds: optional(array(string())),
    userRoleCompanyId: optional(string()),
    userKinds: optional(array(enums(Object.values(IdmUserKind)))),
    withUserCounts: optional(boolean()),
    policyId: optional(nullable(string())),
    withPolicies: optional(boolean())
});

export type GetIdmRolesBody = Infer<typeof GetIdmRolesBodyStruct>;

export const GetIdmUsersBodyStruct = type({
    services: optional(array(serviceStruct)),
    userIds: optional(array(string())),
    userKind: optional(enums(Object.values(IdmUserKind))),
    roleNodeId: optional(number()),
    userRoleCompanyId: optional(string()),
    offset: optional(number()),
    limit: optional(number())
});

export type GetIdmUsersBody = Infer<typeof GetIdmUsersBodyStruct>;

export type GetIdmUsersResponse = B2bIdmTypes.UsersResponse;

export const PaginationArgs = {
    offset: optional(coerce(number(), string(), (value) => parseInt(value))),
    limit: optional(coerce(number(), string(), (value) => parseInt(value)))
};

export const GetIdmUserRolesBodyStruct = type({
    services: optional(array(serviceStruct)),
    userIds: optional(array(string())),
    userKind: optional(enums(Object.values(IdentityBasketUserKind))),
    statuses: optional(array(enums(Object.values(IdmUserRoleStatus)))),
    sortBy: optional(enums(Object.values(GetUserRolesSortBy))),
    searchText: optional(string()),
    sortDirection: optional(enums(Object.values(SortDirection)))
});

export type GetIdmUserRolesBody = Infer<typeof GetIdmUserRolesBodyStruct>;

export const GetIdmUserRoleEventsBodyStruct = type({
    services: optional(array(serviceStruct)),
    roleNodeIds: optional(array(number())),
    userIds: optional(array(string())),
    authorIds: optional(array(string())),
    userRoleCompanyId: optional(string()),
    status: optional(enums(Object.values(IdmUserRoleStatus))),
    offset: optional(number()),
    timeFrom: optional(string()),
    timeTo: optional(string()),
    limit: optional(number())
});

export type GetIdmUserRoleEventsBody = Infer<typeof GetIdmUserRoleEventsBodyStruct>;

export const GetIdmPoliciesListBodyStruct = type({
    roleNodeIds: optional(array(number())),
    authorIds: optional(array(string())),
    timeFrom: optional(string()),
    timeTo: optional(string()),
    ...PaginationArgs
});

export type GetIdmPoliciesListBody = Infer<typeof GetIdmPoliciesListBodyStruct>;

export const RemoveIdmPolicyBodyStruct = type({
    id: string()
});

export type RemoveIdmPolicyBody = Infer<typeof RemoveIdmPolicyBodyStruct>;

export const ExportIdmRolesBodyStruct = type({
    ids: optional(array(string())),
    service: serviceStruct,
    userRoleResourceCompanyIds: optional(array(string()))
});

export type ExportIdmRolesBody = Infer<typeof ExportIdmRolesBodyStruct>;

export const ServiceParamsStruct = type({
    service: serviceStruct
});

export const StringMapStruct = record(string(), string());
export const StringOrStringMapStruct = union([string(), StringMapStruct]);

export const RoleSchemaStruct: Struct<B2bIdmTypes.RoleSchema> = type({
    slug: string(),
    name: optional(StringOrStringMapStruct),
    values: optional(
        record(
            string(),
            union([
                type({
                    name: StringOrStringMapStruct,
                    help: optional(StringOrStringMapStruct),
                    permissions: optional(array(string())),
                    roles: optional(lazy(() => RoleSchemaStruct)),
                    globalEnabled: optional(boolean())
                }),
                StringOrStringMapStruct
            ])
        )
    )
});

export const RoleChoiceFieldTypeStruct: Struct<B2bIdmTypes.RoleChoiceField.type> = enums(
    Object.values(B2bIdmTypes.RoleChoiceField.type)
);
export const RoleChoiceFieldWidgetStruct: Struct<B2bIdmTypes.RoleChoiceField.widget> = enums(
    Object.values(B2bIdmTypes.RoleChoiceField.widget)
);

export const ChoiceFieldOptionStruct = type({
    value: string(),
    name: StringOrStringMapStruct,
    companyId: optional(nullable(string()))
});

export const ChoiceFieldStruct: Struct<B2bIdmTypes.RoleChoiceField> = type({
    type: RoleChoiceFieldTypeStruct,
    slug: string(),
    name: StringOrStringMapStruct,
    required: optional(boolean()),
    options: type({
        widget: optional(RoleChoiceFieldWidgetStruct),
        custom: optional(boolean()),
        multiple: optional(boolean()),
        choices: optional(array(ChoiceFieldOptionStruct))
    })
});
export const FieldStruct = union([ChoiceFieldStruct]);

export const RoleSchemaWithFieldsStruct: Struct<B2bIdmTypes.RoleSchema> = type({
    slug: string(),
    name: optional(StringOrStringMapStruct),
    values: optional(
        record(
            string(),
            union([
                type({
                    name: StringOrStringMapStruct,
                    help: optional(StringOrStringMapStruct),
                    permissions: optional(array(string())),
                    roles: optional(lazy(() => RoleSchemaWithFieldsStruct)),
                    fields: optional(array(FieldStruct)),
                    globalEnabled: optional(boolean())
                }),
                StringOrStringMapStruct
            ])
        )
    )
});

export const ImportIdmRolesBodyStruct = type({
    schema: RoleSchemaWithFieldsStruct,
    service: enums(Object.values(B2bIdmTypes.Service))
});
export type ImportIdmRolesBody = Infer<typeof ImportIdmRolesBodyStruct>;

export const CreateIdmPolicyBodyStruct = type({
    name: string(),
    roleCompanyIds: optional(array(string()))
});

export type CreateIdmPolicyBody = Infer<typeof CreateIdmPolicyBodyStruct>;

export const GetIdmPolicyBodyStruct = type({
    id: string()
});

export type GetIdmPolicyBody = Infer<typeof GetIdmPolicyBodyStruct>;

const CreatePrincipalStruct = type({
    userId: optional(nullable(string())),
    roleNodeId: optional(nullable(number()))
});

const CreatePrincipalQueryStruct = type({
    principals: array(CreatePrincipalStruct)
});

const UpdatePrincipalQueryStruct = type({
    id: string(),
    principals: array(CreatePrincipalStruct)
});

const UpdateRootPrincipalQueryStruct = type({
    children: array(union([UpdatePrincipalQueryStruct, CreatePrincipalQueryStruct]))
});

export const UpdateIdmPolicyBodyStruct = type({
    id: string(),
    name: optional(string()),
    principalQuery: optional(UpdateRootPrincipalQueryStruct)
});

export type UpdateIdmPolicyBody = Infer<typeof UpdateIdmPolicyBodyStruct>;

export const GetIdmSingleUserRolesQueryStruct = type({
    userId: string(),
    userKind: optional(enums(Object.values(IdentityBasketUserKind))),
    services: optional(array(serviceStruct)),
    statuses: optional(array(enums(Object.values(IdmUserRoleStatus)))),
    sortBy: optional(enums(Object.values(GetUserRolesSortBy))),
    searchText: optional(string()),
    sortDirection: optional(enums(Object.values(SortDirection)))
});

export type GetIdmSingleUserRolesQuery = Infer<typeof GetIdmSingleUserRolesQueryStruct>;

export const AddIdmRoleBodyStruct = type({
    roleNodeId: number(),
    uid: string(),
    companyId: optional(string()),
    fields: optional(any())
});

export type AddIdmRoleBody = Infer<typeof AddIdmRoleBodyStruct>;

export const RemoveIdmRoleBodyStruct = type({
    id: number()
});

export type RemoveIdmRoleBody = Infer<typeof RemoveIdmRoleBodyStruct>;

export const IdmRoleTranslationStruct = type({
    languageCode: string(),
    name: string(),
    help: optional(string())
});

export const UpsertIdmRoleBodyStruct = type({
    id: optional(number()),
    service: serviceStruct,
    permissionIds: array(string()),
    minOrganizationLevel: organizationLevelStruct,
    companyId: string(),
    policyId: optional(string()),
    globalEnabled: boolean(),
    specificCompaniesEnabled: boolean(),
    translations: array(IdmRoleTranslationStruct)
});

export type UpsertIdmRoleBody = Infer<typeof UpsertIdmRoleBodyStruct>;

export const GetIdmPermissionsBodyStruct = type({
    service: optional(serviceStruct),
    organizationLevel: optional(organizationLevelStruct)
});

export type GetIdmPermissionsBody = Infer<typeof GetIdmPermissionsBodyStruct>;

export const GetIdmRoleBodyStruct = type({
    id: number()
});

export type GetIdmRoleBody = Infer<typeof GetIdmRoleBodyStruct>;

export const RemoveIdmUserRoleBodyStruct = type({
    userRoleId: number()
});

export type RemoveIdmUserRoleBody = Infer<typeof RemoveIdmUserRoleBodyStruct>;

export const RevokeAllRolesBodyStruct = type({
    userId: string()
});

export type RevokeAllRolesBody = Infer<typeof RevokeAllRolesBodyStruct>;

export const BulkChangeRolesBodyStruct = type({
    userIds: array(string()),
    roles: array(
        type({
            roleNodeId: number(),
            companyId: string(),
            action: enums(Object.values(IdmBulkChangeRolesAction))
        })
    )
});

export type BulkChangeRolesBody = Infer<typeof BulkChangeRolesBodyStruct>;

export const BulkChangePolicyRolesBodyStruct = type({
    policyId: string(),
    roleNodeIds: array(number())
});

export type BulkChangePolicyRolesBody = Infer<typeof BulkChangePolicyRolesBodyStruct>;

export const BulkChangePolicyRoleCompaniesBodyStruct = type({
    policyId: string(),
    companyIds: array(string()),
    anyCompanySelected: optional(boolean())
});

export type BulkChangePolicyRoleCompaniesBody = Infer<typeof BulkChangePolicyRoleCompaniesBodyStruct>;

export type ServiceParams = Infer<typeof ServiceParamsStruct>;

export type IdmUserRole = B2bIdmTypes.UserRoleWithRoleNode;

export type IdmUserRoleWithUserPersonal = B2bIdmTypes.UserRoleWithUserPersonalRoleNode;

export type GetIdmUserRolesResponse = B2bIdmTypes.UserRolesResponse;

export type IdmUserRoleWithUserPersonalRoleNode = B2bIdmTypes.UserRoleWithUserPersonalRoleNode;

export type GetIdmUserRoleEventsResponse = B2bIdmTypes.UserRoleEventsResponse;

export type GetIdmPoliciesResponse = B2bIdmTypes.PoliciesResponse;

export type IdmPolicy = B2bIdmTypes.PolicyWithPrincipalQueryAuthor;

export type UpdateRootPrincipalQuery = B2bIdmTypes.UpdateRootPrincipalQuery;

export type CreateIdmPrincipal = B2bIdmTypes.CreatePrincipal;

export type IdmPrincipalForSelector = B2bIdmTypes.CreatePrincipal & {
    roleNode?: B2bIdmTypes.Role | null;
    user?: Omit<B2bIdmTypes.UserWithPersonal, 'cursor' | 'companyId'> | null;
};

export type IdmRole = B2bIdmTypes.Role;

export type RoleField = B2bIdmTypes.RoleField;

export type UserRoleAttributes = B2bIdmTypes.UserRoleAttributes;

export type RoleChoiceFieldType = B2bIdmTypes.RoleChoiceField.type;
export const RoleChoiceFieldType = B2bIdmTypes.RoleChoiceField.type;

export type RoleChoiceFieldOption = B2bIdmTypes.RoleChoiceFieldOption;

export type IdmCompany = B2bIdmTypes.Company;

export type IdmUserRoleEvent = B2bIdmTypes.UserRoleEventListItem;

export type IdmRoleStatus = B2bIdmTypes.RoleStatus;
export const IdmRoleStatus = B2bIdmTypes.RoleStatus;

export const GetIdmUserRoleRequestsBodyStruct = type({
    processed: optional(boolean()),
    ...PaginationArgs
});

export type GetIdmUserRoleRequestsBody = Infer<typeof GetIdmUserRoleRequestsBodyStruct>;

export type IdmUserWithPersonal = B2bIdmTypes.UserWithPersonal;

export type IdmUserRoleRequestsListItem = B2bIdmTypes.UserRolesRequestsListItem;
export type IdmUserRoleRequestsList = B2bIdmTypes.UserRoleRequestsList;

export const IdmUserRoleRequestStatus = B2bIdmTypes.UserRoleRequestStatus;
export type IdmUserRoleRequestStatus = B2bIdmTypes.UserRoleRequestStatus;

export const RoleFieldChangeStruct = type({
    name: string(),
    value: string(),
    roleNodeId: number()
});

export const BulkRequestRolesBodyStruct = type({
    companyId: string(),
    userIds: array(string()),
    roles: array(number()),
    fields: optional(array(RoleFieldChangeStruct))
});

export type BulkRequestRolesBody = Infer<typeof BulkRequestRolesBodyStruct>;

export type BulkRequestRolesResponse = B2bIdmTypes.BulkRequestRolesResponse;

export const IdmUserRoleRequestResolution = B2bIdmTypes.UserRoleRequestResolution;
export type IdmUserRoleRequestResolution = B2bIdmTypes.UserRoleRequestResolution;

export const ResolveUserRoleRequestsBodyStruct = type({
    userRoleRequestIds: array(number()),
    resolution: enums(Object.values(IdmUserRoleRequestResolution))
});

export type ResolveUserRoleRequestsBody = Infer<typeof ResolveUserRoleRequestsBodyStruct>;

export const IdmPrincipalQueryOperator = B2bIdmTypes.PrincipalQueryOperator;
export type IdmPrincipalQueryOperator = B2bIdmTypes.PrincipalQueryOperator;

export type IdmPermission = B2bIdmTypes.Permission;

export type IdmPermissionDependency = B2bIdmTypes.PermissionDependency;

export type GetIdmPermissionsResponse = B2bIdmTypes.GetPermissionsResponse;

export type ExportIdmRolesResponse = B2bIdmTypes.ExportRolesResponse;

export type IdmGetPendingUserRoleRequestsCountResponse = B2bIdmTypes.GetPendingUserRoleRequestsCountResponse;

export type IdmPolicyRoleCompany = B2bIdmTypes.PolicyRoleCompany;

export const GetIdmUserRoleWithApproversQueryStruct = type({
    user_role_id: string()
});

export type GetIdmUserRoleWithApproversQuery = Infer<typeof GetIdmUserRoleWithApproversQueryStruct>;

export type GetIdmUserRoleWithApproversResponse = B2bIdmTypes.GetUserRoleWithApproversResponse;

export type IdmUserRoleRequest = B2bIdmTypes.UserRoleRequest;

export type IdmUserRoleRequestApproveWithAuthor = B2bIdmTypes.UserRoleRequestApprove &
    B2bIdmTypes.UserRoleRequestApproveAuthorCompanyExtension;

export type IdmPrincipalWithPersonal = B2bIdmTypes.PrincipalWithUserRoleNode &
    B2bIdmTypes.PrincipalWithRoleNodeWithUserRoleWithUserWithPersonalExtension;

export type IdmPrincipalQueryWithPersonal = B2bIdmTypes.PrincipalQueryWithPrincipals &
    B2bIdmTypes.PrincipalQueryPrincipalsWithUsersWithPersonalExtension;

export type IdmPrincipalQueryWithChildren = B2bIdmTypes.PrincipalQueryWithChildren;

export type IdmPolicyWithPrincipalQueryWithChildrenWithPrincipalsWithUserRoleNodeWithApprovers =
    B2bIdmTypes.PolicyWithPrincipalQueryWithChildrenWithPrincipalsWithUserRoleNodeWithApprovers;

export type IdmPrincipalQueryWithChildrenWithPrincipalsWithUserRoleNodeWithApprovers =
    IdmPolicyWithPrincipalQueryWithChildrenWithPrincipalsWithUserRoleNodeWithApprovers['principalQuery'];

export type IdmUserRoleRequestWithApprovesWithUsers = B2bIdmTypes.UserRoleRequest &
    B2bIdmTypes.UserRoleRequestApprovesWithAuthorCompanyExtension;
