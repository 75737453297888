import {yupResolver} from '@hookform/resolvers/yup';
import {Button} from '@lavka/ui-kit';
import {block} from '@lavka-birds/client';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import {useI18n} from '@/src/i18n';
import {useDetermineCompany} from 'client/api/determine-company';
import {useRedirectToLoginPage} from 'client/pages/select-company-page/select-company-page-actions';
import {selectCompanySlug} from 'client/store/app';
import {InputField} from 'client/ui/form-fields/input';

import styles from './company-slug-enter.css';

interface CompanySlugEnterFormValues {
    slugOrEmail: string;
}

const formSchema: yup.SchemaOf<CompanySlugEnterFormValues> = yup
    .object({
        slugOrEmail: yup.string().required()
    })
    .required();

interface PartnerSlugEnterProps {
    className?: string;
}

export function CompanySlugEnter({className}: PartnerSlugEnterProps) {
    const cn = block(styles, 'company-slug-enter', className);

    const i18n = useI18n();
    const partnerSlug = useSelector(selectCompanySlug);
    const redirectToLogin = useRedirectToLoginPage();

    const {isLoading, mutateAsync: determineCompany} = useDetermineCompany();

    const defaultValues = React.useMemo(
        () => ({
            slugOrEmail: partnerSlug
        }),
        [partnerSlug]
    );
    const {
        control,
        setError,
        handleSubmit: handleHookFormSubmit
    } = useForm<CompanySlugEnterFormValues>({
        defaultValues,
        resolver: yupResolver(formSchema)
    });

    const handleSubmit = React.useCallback(
        async ({slugOrEmail}: CompanySlugEnterFormValues) => {
            const {companyBySlugOrEmail} = await determineCompany({slugOrEmail});
            if (companyBySlugOrEmail) {
                redirectToLogin(companyBySlugOrEmail.slug);
            } else {
                setError('slugOrEmail', {message: i18n('sign-in', 'incorrect-company-slug-error')});
            }
        },
        [determineCompany, setError, i18n, redirectToLogin]
    );

    return (
        <div className={cn()}>
            <div className={cn('title')}>{i18n('sign-in', 'login')}</div>
            <form onSubmit={handleHookFormSubmit(handleSubmit)}>
                <InputField
                    size="m"
                    name="slugOrEmail"
                    className={cn('input')}
                    control={control}
                    type="text"
                    placeholder={i18n('sign-in', 'enter-company-slug')}
                />
                <Button className={cn('button')} fullWidth loading={isLoading} type="submit" view="action" size="m">
                    {i18n('sign-in', 'next')}
                </Button>
            </form>
        </div>
    );
}
