import qs from 'qs';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {generatePathCompanyBase, generatePathLoginCookie} from 'client/pages/paths';
import type {CookieLoginQuery} from 'types/login';

import {selectRedirectUri, selectState} from './select-company-page-selectors';

export function useRedirectToLoginPage() {
    const redirectUri = useSelector(selectRedirectUri);
    const state = useSelector(selectState);

    return useCallback(
        (companySlug: string) => {
            const loginPagePath = generatePathLoginCookie({partnerOrCompanyId: companySlug});
            const cookieLoginQuery: CookieLoginQuery = {
                redirect_uri: redirectUri ?? generatePathCompanyBase({partnerOrCompanyId: companySlug}),
                ...(state ? {state} : undefined)
            };
            const search = qs.stringify(cookieLoginQuery, {addQueryPrefix: true});
            window.location.href = `${loginPagePath}${search}`;
        },
        [redirectUri, state]
    );
}
