const NO_CSRF_ROUTES = ['/courier-sign-in/manifest'];

export const shouldCheckCsrf = (method: string | undefined, path?: string, isE2e?: boolean) => {
    if (isE2e) {
        return false;
    }

    if (path && NO_CSRF_ROUTES.some((route) => path === route)) {
        return false;
    }

    const m = method?.toLowerCase();

    return m !== 'head' && m !== 'options';
};
