import {Api, BaseRequestClient} from '@lavka-birds/client';

import {api} from './request-api';

export abstract class BaseClient extends BaseRequestClient {
    protected _api = api;

    constructor(api?: Api) {
        super();
        if (api) {
            this._api = api;
        }
    }
}
