import {useMutation} from 'react-query';
import {useDispatch} from 'react-redux';

import type {ApiResponseError} from '@/src/errors';
import {determineCompanyClient} from 'client/api/client/endpoints/determine-company';
import {notificationsActions} from 'client/scenes/notifications/notifications-reducer';
import {NotificationType} from 'client/scenes/notifications/types';
import type {DetermineCompanyQuery, DetermineCompanyResponse} from 'types/companies';

export function useDetermineCompany() {
    const dispatch = useDispatch();

    return useMutation<DetermineCompanyResponse, ApiResponseError, DetermineCompanyQuery>(
        (params: DetermineCompanyQuery) => determineCompanyClient.get(params),
        {
            onError: (error) => {
                dispatch(notificationsActions.show({text: error.message, type: NotificationType.ERROR}));
            }
        }
    );
}
