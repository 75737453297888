import {ClientLogger, LogErrorOptions} from '@lavka-birds/client';

import {loggerClient} from 'client/api/client/endpoints/logger';
import {config} from 'client/config';

class Logger extends ClientLogger {
    protected additional?: LogErrorOptions['additional'];
    protected prefix?: string;

    logError(err: Error, options: LogErrorOptions): void {
        super.logError(err, options);

        if (process.env.__ENVIRONMENT__ === 'browser') {
            const message = [this.prefix, options.prefix, err.message].filter(Boolean).join(' ');

            void loggerClient.logError({
                error: {message, stack: err.stack},
                meta: {
                    ...options,
                    additional: {
                        ...this.additional,
                        ...options.additional
                    }
                }
            });
        }
    }

    updateAdditional(additional: LogErrorOptions['additional']): void {
        this.additional = additional;
    }

    updatePrefix(prefix: string): void {
        this.prefix = prefix;
    }
}

const {
    serviceName,
    version,
    clientLogger: {logUncaughtException, logUnhandledRejection, logToConsoleOnly, env}
} = config.app;

export const logger = new Logger({
    serviceName,
    version,
    env,
    unhandledRejection: logUnhandledRejection,
    uncaughtException: logUncaughtException,
    logToConsole: logToConsoleOnly
});

if (process.env.__ENVIRONMENT__ === 'browser') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Logger = require('@yandex-int/error-counter/dist/logger');
    logger.init(new Logger());
}
