import React from 'react';

import {Spin} from 'client/ui/spin';

import styles from './page-fallback.css';

const SPIN_DELAY = 500;

export const PageFallback: React.FC = () => {
    return (
        <div className={styles.fallback}>
            <Spin size="large" delay={SPIN_DELAY} />
        </div>
    );
};
