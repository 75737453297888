import React from 'react';

import {NotificationType} from 'client/scenes/notifications/types';
import {IconCheck} from 'client/ui/icons/icon-check';
import {IconCrossBanFill} from 'client/ui/icons/icon-cross-ban-fill';

interface NotificationIconProps {
    className?: string;
    type?: NotificationType;
    size?: number;
}

export function NotificationIcon({className, type, size}: NotificationIconProps) {
    if (type === NotificationType.SUCCESS) {
        return <IconCheck className={className} size={size} />;
    }

    if (type === NotificationType.ERROR) {
        return <IconCrossBanFill className={className} size={size} />;
    }

    return null;
}
