import {BaseClient} from 'client/api/client/base-client';
import {ROUTES} from 'constants/routes';
import type {DetermineCompanyQuery, DetermineCompanyResponse} from 'types/companies';

const {DETERMINE_COMPANY} = ROUTES.API.V1;

class DetermineCompanyClient extends BaseClient {
    protected _base = DETERMINE_COMPANY;

    get(query: DetermineCompanyQuery) {
        return this._get<DetermineCompanyQuery, DetermineCompanyResponse>('', {query});
    }
}

export const determineCompanyClient = new DetermineCompanyClient();
