import {createSlice} from '@reduxjs/toolkit';

interface SelectCompanyPageState {
    redirectUri?: string;
    state?: string;
}

const initialState: SelectCompanyPageState = {};

export const selectCompanyPageSlice = createSlice({
    name: 'select-company-page',
    initialState,
    reducers: {}
});

export const {actions, reducer} = selectCompanyPageSlice;

export {actions as selectCompanyPageActions, reducer as selectCompanyPageReducer};
