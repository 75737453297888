import {Svg} from '@lavka/ui-kit';
import React from 'react';

interface Props {
    className?: string;
    size?: number;
}

export function IconCrossBanFill({className, size = 24}: Props) {
    return (
        <Svg className={className} size={size} viewBox="0 0 24 24">
            <path
                fill="currentColor"
                fillRule="evenodd"
                // eslint-disable-next-line max-len
                d="M8.828 3h6.344a2 2 0 0 1 1.414.586l3.828 3.828A2 2 0 0 1 21 8.828v6.344a2 2 0 0 1-.586 1.414l-3.828 3.828a2 2 0 0 1-1.414.586H8.828a2 2 0 0 1-1.414-.586l-3.828-3.828A2 2 0 0 1 3 15.172V8.828a2 2 0 0 1 .586-1.414l3.828-3.828A2 2 0 0 1 8.828 3Zm7.053 5.811a.5.5 0 0 1 0 .708L13.4 12l2.482 2.482a.5.5 0 0 1 0 .707l-.693.693a.5.5 0 0 1-.707 0L12 13.4l-2.483 2.482a.5.5 0 0 1-.707 0l-.693-.692a.5.5 0 0 1 0-.708L10.6 12 8.118 9.518a.5.5 0 0 1 0-.707l.693-.693a.5.5 0 0 1 .707 0L12 10.6l2.482-2.481a.5.5 0 0 1 .707 0l.692.692Z"
            />
        </Svg>
    );
}
