import {generatePath} from 'react-router';

import {RolesTab} from 'client/pages/users-pages/roles-page/types';
import {PAGE_PATHS} from 'constants/routes';
import type {CompanyPathParams} from 'types/path';

export function generatePathCompanyBase({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.PARTNER_OR_COMPANY_BASE, {partnerOrCompanyId});
}

export function generatePathUsersList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USERS_LIST, {partnerOrCompanyId});
}

export function generatePathUserRolesList({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_LIST, {partnerOrCompanyId});
}

export function generatePathUserRolesEvents({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_EVENTS, {partnerOrCompanyId});
}

export function generatePathUserRolesPolicies({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_POLICIES, {partnerOrCompanyId});
}

export function generatePathUserRolesRequest({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_ROLES_REQUESTS, {partnerOrCompanyId});
}

export function generatePathUserRolesRequestView({partnerOrCompanyId, roleId}: CompanyPathParams & {roleId: number}) {
    return generatePath(PAGE_PATHS.USER_ROLES_REQUESTS_VIEW, {partnerOrCompanyId, roleId});
}

export function generatePathUserView({partnerOrCompanyId, userId}: CompanyPathParams & {userId: string}) {
    return generatePath(PAGE_PATHS.USER_VIEW, {partnerOrCompanyId, userId});
}

export function generatePathUserViewRoles({partnerOrCompanyId, userId}: CompanyPathParams & {userId: string}) {
    return generatePath(PAGE_PATHS.USER_VIEW_ROLES, {partnerOrCompanyId, userId});
}

export function generatePathUserViewRoleView({
    partnerOrCompanyId,
    userId,
    roleId
}: CompanyPathParams & {userId: string; roleId: string}) {
    return `${generatePath(PAGE_PATHS.USER_VIEW_ROLES_VIEWS, {partnerOrCompanyId, userId, roleId})}${location.search}`;
}

export function generatePathUserImportPreview({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.USER_IMPORT_PREVIEW, {partnerOrCompanyId});
}

export function generatePathPolicyView({
    partnerOrCompanyId,
    policyId,
    policyTab
}: CompanyPathParams & {policyId: string; policyTab?: string}) {
    return generatePath(PAGE_PATHS.POLICY_VIEW, {partnerOrCompanyId, policyId, policyTab});
}

export function generatePathPoliciesListView({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.POLICIES_LIST, {rolesTab: RolesTab.POLICIES, partnerOrCompanyId});
}

export function generatePathLoginCookie({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.LOGIN_COOKIE, {partnerOrCompanyId});
}

export function generatePathLogout({partnerOrCompanyId}: CompanyPathParams) {
    return generatePath(PAGE_PATHS.LOGOUT, {partnerOrCompanyId});
}

export function generatePathCourier() {
    return generatePath(PAGE_PATHS.COURIER_SIGN_IN);
}

export function generatePathCreateRole({partnerOrCompanyId, roleEditTab}: CompanyPathParams & {roleEditTab?: string}) {
    return generatePath(PAGE_PATHS.ROLE_CREATE, {partnerOrCompanyId, roleEditTab});
}

export function generatePathEditRole({
    partnerOrCompanyId,
    roleId,
    roleEditTab
}: CompanyPathParams & {roleId: number; roleEditTab?: string}) {
    return generatePath(PAGE_PATHS.ROLE_EDIT, {partnerOrCompanyId, roleId, roleEditTab});
}

export function generateFullPath(path: string) {
    return `${window.location.protocol}//${window.location.host}${path}`;
}
