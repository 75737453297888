// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
__webpack_nonce__ = window.__NONCE__;
import React from 'react';
import ReactDOM from 'react-dom';

import {type UILanguage, i18n} from '@/src/i18n';

import {AppWrapper} from './pages/index';

const lang = (window.__LANG__ ?? i18n.defaultLang) as UILanguage;

void Promise.all([i18n.loadI18n(lang)]).then(() => {
    ReactDOM.render(<AppWrapper initLang={lang} />, document.getElementById('root'), () => console.log('ready'));
});
