import {useSelector} from 'react-redux';

import {selectCompanyId, selectCompanySlug} from 'client/store/app';

export const useCompanyIdStrict = (): string => {
    const companyId = useSelector(selectCompanyId);

    if (!companyId) {
        throw new Error('useCompanyIdStrict must be called inside of company route');
    }

    return companyId;
};

export const useCompanySlugStrict = (): string => {
    const companySlug = useSelector(selectCompanySlug);

    if (!companySlug) {
        throw new Error('useCompanySlugStrict must be called inside of company route');
    }

    return companySlug;
};
