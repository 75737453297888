export enum RolesTab {
    LIST = 'list',
    EVENTS = 'events',
    REQUESTS = 'requests',
    POLICIES = 'policies'
}

export interface RolesPageParams {
    rolesTab: RolesTab;
}
