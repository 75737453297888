import './spin.antd.css';

import {block as bemBlock} from '@lavka-birds/client';
import AntdSpin, {SpinProps as AntdSpinProps} from 'antd/lib/spin';
import React from 'react';

import styles from './spin.css';

export interface SpinProps extends AntdSpinProps {
    centered?: boolean;
    block?: boolean;
}

const SpinComponent: React.FC<SpinProps> = ({centered, className, block, ...restProps}) => {
    const cn = bemBlock(styles, 'spin', className);

    return (
        <div className={cn({centered, block})}>
            <AntdSpin {...restProps} />
        </div>
    );
};

export const Spin = React.memo(SpinComponent) as React.FC<SpinProps>;
