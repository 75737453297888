import React from 'react';
import {Redirect, RedirectProps, Route} from 'react-router-dom';

interface Props extends RedirectProps {
    status: number;
}

export const RedirectWithStatus: React.FC<Props> = ({status, ...restProps}) => (
    <Route
        render={({staticContext}) => {
            if (staticContext) {
                staticContext.statusCode = status;
            }

            return <Redirect {...restProps} />;
        }}
    />
);
