import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ClientUsersImportStatus} from './types';

export interface UsersImportState {
    lastImportId?: number;
    status: ClientUsersImportStatus;
    usersAdded?: number;
    usersFailed?: number;
}

const initialState: UsersImportState = {
    status: ClientUsersImportStatus.NOT_STARTED
};

export const usersImportSlice = createSlice({
    name: 'users-import',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<UsersImportState>) => {
            state.lastImportId = action.payload.lastImportId;
            state.status = action.payload.status;
            state.usersAdded = action.payload.usersAdded;
            state.usersFailed = action.payload.usersFailed;
        }
    }
});
export const {actions, reducer} = usersImportSlice;

export {reducer as usersImportReducer, actions as usersImportActions};
