import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {UploadImportListResponse} from 'types/users';

interface UsersImportPreviewState {
    importData?: UploadImportListResponse;
}

const initialState: UsersImportPreviewState = {};

export const usersImportPreviewSlice = createSlice({
    name: 'users-import-preview',
    initialState,
    reducers: {
        setImportData: (state, {payload: importData}: PayloadAction<UploadImportListResponse>) => {
            state.importData = importData;
        }
    }
});

export const {actions, reducer} = usersImportPreviewSlice;

export {actions as usersImportPreviewActions, reducer as usersImportPreviewReducer};
