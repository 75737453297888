import {createSelector} from '@reduxjs/toolkit';

import {ClientUsersImportStatus} from 'client/scenes/users/users-import-controller/types';
import type {RootState} from 'client/store/configure-store';

export const selectUsersImportState = (state: RootState) => state.scenes.usersImport;

export const selectIsUsersImportDisabled = createSelector(
    selectUsersImportState,
    (state) => state.status === ClientUsersImportStatus.PROGRESS
);
