import type {UsersImportEntity} from '@/src/entities/users-import/entity';
import {BaseClient} from 'client/api/client/base-client';
import {ROUTES} from 'constants/routes';
import type {
    CreateUserBody,
    CreateUsersMultipleBody,
    DeleteUserBody,
    GetUserQuery,
    GetUsersQuery,
    GetUsersResponse,
    IdpUser,
    LogoutUserGloballyBody,
    ResetUserPasswordBody,
    UpdateUserBody,
    UpdateUserStatusBody
} from 'types/users';

const {USERS} = ROUTES.API.V1.PARTNER_OR_COMPANY;

import {companyApi} from 'client/api/client/request-api';

class UsersClient extends BaseClient {
    protected _base = USERS.BASE;

    constructor() {
        super(companyApi);
    }

    getUser(query: GetUserQuery) {
        return this._get<GetUserQuery, IdpUser>(USERS.GET, {query});
    }

    getUsersList(query: GetUsersQuery) {
        return this._get<GetUsersQuery, GetUsersResponse>(USERS.GET_LIST, {query});
    }

    createUser(data: CreateUserBody) {
        return this._post<CreateUserBody, IdpUser | undefined>(USERS.CREATE, {body: data});
    }

    updateUser(data: UpdateUserBody) {
        return this._post<UpdateUserBody, void>(USERS.UPDATE, {body: data});
    }

    updateUserStatus(data: UpdateUserStatusBody) {
        return this._post<UpdateUserStatusBody, void>(USERS.UPDATE_STATUS, {body: data});
    }

    deleteUser(data: DeleteUserBody) {
        return this._post<DeleteUserBody, void>(USERS.DELETE, {body: data});
    }

    resetUserPassword(data: ResetUserPasswordBody) {
        return this._post<ResetUserPasswordBody, void>(USERS.RESET_PASSWORD, {body: data});
    }

    createUsersMultiple(data: CreateUsersMultipleBody) {
        return this._post<CreateUsersMultipleBody, UsersImportEntity>(USERS.CREATE_MULTIPLE, {body: data});
    }

    getLastUsersImport() {
        return this._get<{}, UsersImportEntity | undefined>(USERS.GET_LAST_IMPORT_FOR_USER, {});
    }

    logoutAnotherUserGlobally(data: LogoutUserGloballyBody) {
        return this._post<LogoutUserGloballyBody, void>(USERS.LOGOUT_ANOTHER_USER_GLOBALLY, {body: data});
    }
}

export const usersClient = new UsersClient();
