export const FEATURES_HEADER_NAME = 'x-grocery-features';

export const RAW_CONTENT_TYPE = 'application/octet-stream';

export const FORM_CONTENT_TYPE = 'application/x-www-form-urlencoded';

export const XLSX_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const DEFAULT_CACHE_DURATION = 3600;

export const DEFAULT_COURIERS_DATA_LIMIT = 100;

export const COMPANY_ID_STUB = '53ebf013aef6414cb8ff63597b2d8b34000300020000';

// Для примера посмотрел размер эксельки с 1000 записей - вышло 78 Кб.
// 1 Мб нам должно хватить
export const MAX_UPLOAD_USERS_IMPORT_LIST_SIZE_MB = 1;

export const TIMEOUT_ERROR_MESSAGE = 'ETIMEDOUT';

export const ROOT_ROLE_GROUP = 'roles';

export const CONFIGS_HEADER_NAME = 'X-Configs';

export const AUTH_CODE_COOKIE_NAME = 'auth_code';
