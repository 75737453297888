/* eslint-disable max-len */
import {Svg} from '@lavka/ui-kit';
import React from 'react';

import {IconFactory} from '@/src/client/ui/icons/icon-factory';

interface Props {
    className?: string;
}

export function IconYangoTech({className}: Props) {
    return (
        <IconFactory>
            <Svg className={className} width="134" height="24" viewBox="0 0 134 24" fill="none">
                <path
                    d="M8.49725 0.621193H3.49056L3.42638 15.2882L0.730469 23.408H5.80134L8.36887 15.6733L18.1897 0.621193H12.509L9.26751 6.5907C8.65772 7.6819 8.1763 8.67682 8.01583 9.06195C8.08002 8.64472 8.1763 7.36096 8.24049 6.30185L8.49725 0.621193Z"
                    fill="#1A1817"
                />
                <path
                    d="M7.82628 23.408H12.9934L14.9191 19.3H18.995L18.289 23.408H23.1673L26.473 0.621193H19.6369L7.82628 23.408ZM19.7653 14.9994H16.9731L20.4072 7.6819C20.9528 6.5586 21.37 5.53159 21.5305 5.14646C21.4663 5.56369 21.2095 6.81536 21.017 7.87446L19.7653 14.9994Z"
                    fill="#1A1817"
                />
                <path
                    d="M36.7358 0.621193H31.2156L23.6414 23.408H28.2951L32.2105 11.5011C32.6278 10.3136 32.9166 9.25451 33.045 8.86938C33.0129 9.2866 32.8845 10.5704 32.8203 11.6295L32.1143 23.408H37.5061L45.0803 0.621193H40.4266L36.7037 11.9504C36.2865 13.1379 35.9976 14.197 35.8693 14.5821C35.9014 14.1649 35.9976 12.8811 36.0618 11.822L36.7358 0.621193Z"
                    fill="#1A1817"
                />
                <path
                    d="M50.6865 14.8068L50.5902 15.0956C49.8841 17.2459 48.7287 19.653 47.3166 19.653C45.7119 19.653 46.3217 15.8338 47.6696 11.7258C49.5632 5.94882 50.7186 4.31202 52.0344 4.31202C52.7726 4.31202 53.254 5.05018 53.4145 7.6498L57.9397 6.14138C57.7472 2.83569 56.1746 0.171875 52.5158 0.171875C48.3115 0.171875 44.9737 3.73432 42.6308 10.8913C40.4805 17.4706 40.6731 23.5685 45.1984 23.5685C46.771 23.5685 47.8622 22.9587 48.9855 21.5787L48.6645 23.408H52.2912L56.5918 10.5062H49.6595L48.2473 14.8068H50.6865Z"
                    fill="#1A1817"
                />
                <path
                    d="M61.4688 23.8252C65.4164 23.8252 68.8826 20.2949 71.1934 13.1379C73.3437 6.5586 73.3116 0.203969 67.1174 0.203969C63.1698 0.203969 59.7037 3.73432 57.3929 10.8913C55.2426 17.4706 55.2747 23.8252 61.4688 23.8252ZM61.9823 19.7493C60.0567 19.7493 61.18 15.5129 62.4317 11.7258C64.3252 5.85253 65.5127 4.27992 66.6039 4.27992C68.4975 4.27992 67.4063 8.45216 66.1546 12.3035C64.261 18.1767 63.0736 19.7493 61.9823 19.7493Z"
                    fill="#1A1817"
                />
                <path
                    d="M79.412 0.621193L77.9998 4.9218H82.0116L75.8495 23.408H80.9204L87.0825 4.9218H91.1584L92.5705 0.621193H79.412Z"
                    fill="#1A1817"
                />
                <path
                    d="M93.5233 0.621193L85.9491 23.408H97.0216L98.4337 19.1074H92.4322L94.2294 13.7477H99.6212L101.065 9.44708H95.6737L97.1821 4.9218H103.055L104.467 0.621193H93.5233Z"
                    fill="#1A1817"
                />
                <path
                    d="M105.692 23.7931C109.318 23.7931 111.693 20.9047 113.073 18.2088L109.286 16.4115C107.874 18.8827 107.136 19.6209 106.237 19.6209C104.504 19.6209 105.306 15.8338 106.686 11.7258C108.516 6.26976 109.639 4.31202 110.987 4.31202C111.918 4.31202 112.239 5.59578 112.399 7.52143L116.764 5.94882C116.668 2.51475 115.127 0.203969 111.501 0.203969C107.36 0.203969 103.991 3.73432 101.648 10.8913C99.4974 17.4706 99.5616 23.7931 105.692 23.7931Z"
                    fill="#1A1817"
                />
                <path
                    d="M127.916 0.621193L125.092 9.15823H121.69L124.514 0.621193H119.443L111.869 23.408H116.94L120.213 13.5872H123.615L120.342 23.408H125.413L132.987 0.621193H127.916Z"
                    fill="#1A1817"
                />
            </Svg>
        </IconFactory>
    );
}
