export const enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    USERS_IMPORT = 'users-import'
}

export interface CreateNotificationData {
    type: NotificationType;
    text?: string;
    permanent?: boolean;
}

export interface NotificationData extends CreateNotificationData {
    id: string;
}
