import type {IdentityBasketUserKind} from '@lavka-birds/b2b-common';
import {createSelector} from '@reduxjs/toolkit';

import {decodeURIWithPlus} from '@/src/lib/decode-uri-with-plus';
import type {ErrorPageCode} from 'client/pages/query-error-page/types';
import type {RootState} from 'client/store/configure-store';
import {IdmUserRoleStatus} from 'types/idm';

import {MapQueryParams} from './types';

export const routerSelector = (state: RootState) => state.router;

export const selectAuthCodeParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.CODE];
});

export const selectRedirectUriParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.REDIRECT_URI];
});

export const selectStateParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.STATE];
});

export const selectUserKindParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.USER_KIND] as IdentityBasketUserKind | undefined;
});

export const selectUserRolesStatusParameter = createSelector(routerSelector, (state) => {
    return (state.location.query[MapQueryParams.STATUS] || IdmUserRoleStatus.ISSUED) as IdmUserRoleStatus;
});

export const selectErrorParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.ERROR] as ErrorPageCode;
});

export const selectErrorTitleParameter = createSelector(routerSelector, (state) => {
    return state.location.query[MapQueryParams.ERROR_TITLE];
});

export const selectErrorDescriptionParameter = createSelector(routerSelector, (state) => {
    const errorDescription = state.location.query[MapQueryParams.ERROR_DESCRIPTION];

    return errorDescription && decodeURIWithPlus(errorDescription);
});

export const selectReturnUrlParameter = createSelector(routerSelector, (state) => {
    const returnUrl = state.location.query[MapQueryParams.RETURN_URL];

    return returnUrl && decodeURIComponent(returnUrl);
});
