import {
    ALL_COMPANIES,
    ANY_COMPANY,
    getLTreePathFromSlugs,
    IdentityBasketUserKind,
    IdentitySource,
    ReservedRoleNodeSlug
} from '@lavka-birds/b2b-common';

import {ROOT_ROLE_GROUP} from '@/src/constants';
import {PermissionPath, PERMISSIONS} from 'constants/permissions';
import {Feature, FeaturesConfig} from 'types/features';
import type {AuthUser} from 'types/login';

interface PermissionsParams {
    currentBasketIdentitySource?: IdentitySource;
    currentUser?: AuthUser;
    currentCompanySlug?: string;
    currentCompanyId?: string;
    currentCompanyIsService?: boolean;
    features: FeaturesConfig;
    currentUserPermissions: string[];
}

interface UserIds {
    userId?: string;
    fullUsername?: string;
}

interface CanReadUserParams extends UserIds {
    userKind?: IdentityBasketUserKind;
}

type PermissionsSet = Set<string>;

export const getPermissionPrefixSlugs = (companyId = ALL_COMPANIES) => [
    ROOT_ROLE_GROUP,
    ReservedRoleNodeSlug.CONSTRUCTOR,
    ReservedRoleNodeSlug.COMPANY,
    companyId,
    ReservedRoleNodeSlug.ROLES
];

export const getPermissionPath = (permissionPath: PermissionPath, companyId: string | null | undefined) =>
    getLTreePathFromSlugs([...getPermissionPrefixSlugs(companyId ?? ALL_COMPANIES), ...permissionPath.split('.')]);

export class Permissions {
    currentBasketIdentitySource?: IdentitySource;
    currentUser?: AuthUser;
    currentCompanySlug?: string;
    currentCompanyId: string;
    currentCompanyIsService: boolean;
    features: FeaturesConfig;
    currentUserPermissionsSet: PermissionsSet;

    constructor({
        currentBasketIdentitySource,
        currentUser,
        currentCompanySlug,
        currentCompanyIsService,
        currentCompanyId = ALL_COMPANIES,
        features,
        currentUserPermissions = []
    }: PermissionsParams) {
        this.currentBasketIdentitySource = currentBasketIdentitySource;
        this.currentUser = currentUser;
        this.currentCompanySlug = currentCompanySlug;
        this.currentCompanyId = currentCompanyId;
        this.currentCompanyIsService = Boolean(currentCompanyIsService);
        this.currentUserPermissionsSet = new Set(currentUserPermissions);
        this.features = features;
    }

    canListCompanies(companyId = this.currentCompanyId) {
        return (
            this.currentUser?.kind === IdentityBasketUserKind.GLOBAL &&
            this.currentCompanySlug === 'yandex' &&
            this.hasPermission(PERMISSIONS.COMPANIES.ACTION.LIST, companyId)
        );
    }

    canReadUsers(userKind?: IdentityBasketUserKind, companyId = this.currentCompanyId): boolean {
        if (userKind === 'courier') {
            return this.hasPermission(PERMISSIONS.USERS.ACTION.LIST_COURIERS, companyId);
        }

        return this.hasPermission(PERMISSIONS.USERS.ACTION.LIST, companyId);
    }

    canReadUser({userKind, ...userIds}: CanReadUserParams, companyId = this.currentCompanyId): boolean {
        if (this._isCurrentUser(userIds)) {
            return true;
        }

        return this.canReadUsers(userKind, companyId);
    }

    canCreateUsers(companyId = this.currentCompanyId) {
        return this.hasPermission(PERMISSIONS.USERS.ACTION.CREATE, companyId);
    }

    canUpdateUsers(companyId = this.currentCompanyId) {
        return (
            this.hasPermission(PERMISSIONS.USERS.ACTION.MANAGE, companyId) &&
            (this.features[Feature.ALLOW_EXTERNAL_IDP_USERS_WRITE] ||
                this.currentBasketIdentitySource === IdentitySource.NATIVE)
        );
    }

    canUpdateUserStatus(userIds: UserIds, companyId = this.currentCompanyId) {
        return !this._isCurrentUser(userIds) && this.hasPermission(PERMISSIONS.USERS.ACTION.MANAGE, companyId);
    }

    canLogoutAnotherUserGlobally(companyId = this.currentCompanyId) {
        return this.hasPermission(PERMISSIONS.USERS.ACTION.MANAGE, companyId);
    }

    canDeleteUser(userIds: UserIds): boolean {
        return this.canUpdateUsers() && !this._isCurrentUser(userIds);
    }

    canReadRoles(companyId = this.currentCompanyId): boolean {
        // Пользователь без ролей должен иметь возможность запросить себе роль
        return (
            (this.currentUser?.kind === IdentityBasketUserKind.GLOBAL && this.currentCompanySlug === 'yandex') ||
            this.currentUser?.company?.id === companyId ||
            this.hasPermission(PERMISSIONS.ROLES.ACTION.READ, companyId)
        );
    }

    canReadUserRoles(userIds?: UserIds, companyId = this.currentCompanyId): boolean {
        if (userIds && this._isCurrentUser(userIds)) {
            return true;
        }

        return this.hasPermission(PERMISSIONS.USER_ROLES.ACTION.READ, companyId);
    }

    canRequestUserRoles(userIds?: UserIds, companyId = this.currentCompanyId): boolean {
        if (userIds && this._isCurrentUser(userIds)) {
            return true;
        }

        return this.hasPermission(PERMISSIONS.USER_ROLES.ACTION.REQUEST, companyId);
    }

    canAddUserRole(companyId = this.currentCompanyId): boolean {
        return this.hasPermission(PERMISSIONS.USER_ROLES.ACTION.ADD, companyId);
    }

    canRequestUserRole(userId?: string, companyId = this.currentCompanyId): boolean {
        if (userId === this.currentUser?.id) {
            return true;
        }

        return this.hasPermission(PERMISSIONS.USER_ROLES.ACTION.REQUEST, companyId);
    }

    canRevokeRole(userId?: string, companyId = this.currentCompanyId): boolean {
        if (userId === this.currentUser?.id) {
            return true;
        }

        return this.hasPermission(PERMISSIONS.USER_ROLES.ACTION.REVOKE, companyId);
    }

    canWriteRoles(companyId = this.currentCompanyId): boolean {
        return this.hasPermission(PERMISSIONS.ROLES.ACTION.WRITE, companyId);
    }

    canReadPolicies(companyId = this.currentCompanyId): boolean {
        return this.hasPermission(PERMISSIONS.POLICIES.ACTION.READ, companyId);
    }

    canWritePolicies(companyId = this.currentCompanyId): boolean {
        return this.hasPermission(PERMISSIONS.POLICIES.ACTION.WRITE, companyId);
    }

    static hasPermission(set: Set<string>, permissionPath: PermissionPath, companyId: string) {
        return (
            set.has(getPermissionPath(permissionPath, companyId)) ||
            set.has(getPermissionPath(permissionPath, ALL_COMPANIES)) ||
            set.has(getPermissionPath(permissionPath, ANY_COMPANY))
        );
    }

    hasPermission(permissionPath: PermissionPath, companyId: string) {
        // Deny all requests for non service company where companyId in body not equal to current companyId
        if (!this.currentCompanyIsService && companyId !== this.currentCompanyId) {
            return false;
        }

        return Permissions.hasPermission(this.currentUserPermissionsSet, permissionPath, companyId);
    }

    private _isCurrentUser({userId, fullUsername}: UserIds): boolean {
        if (userId) {
            return userId === this.currentUser?.id;
        }
        if (fullUsername) {
            return fullUsername === this.currentUser?.fullUsername;
        }
        return false;
    }
}
