import {NotificationType} from './types';

export const standardNotificationTypes = [NotificationType.SUCCESS, NotificationType.ERROR] as const;
export type StandardNotificationType = (typeof standardNotificationTypes)[number];

export const isStandardNotification = (type: NotificationType): type is StandardNotificationType => {
    const standardTypes: readonly NotificationType[] = standardNotificationTypes;

    return standardTypes.includes(type);
};
