import {Input, InputProps, InputType} from '@lavka/ui-kit';
import {block} from '@lavka-birds/client';
import React from 'react';
import {FieldValues, useController, UseControllerProps} from 'react-hook-form';

import styles from './input.css';

interface InputFieldProps<TInputType extends InputType, TFieldValues extends FieldValues = FieldValues>
    extends Omit<InputProps<TInputType>, 'defaultValue' | 'name' | 'ref' | 'value' | 'onBlur' | 'onChange'>,
        UseControllerProps<TFieldValues> {
    // Иначе почему-то получается тип "boolean | string | undefined" и TS не даёт его присвоить
    capture?: boolean | 'user' | 'environment' | undefined;
}

export function InputField<TInputType extends InputType, TFieldValues extends FieldValues = FieldValues>(
    props: InputFieldProps<TInputType, TFieldValues>
) {
    const {name, rules, shouldUnregister, defaultValue, control, className, ...inputProps} = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, shouldUnregister, defaultValue, control});
    const cn = block(styles, 'input-field', className);

    return (
        <div className={cn()}>
            <Input {...inputProps} {...field} invalid={Boolean(error)} />
            {error ? <div className={cn('error')}>{error.message}</div> : null}
        </div>
    );
}
