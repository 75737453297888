import {Svg} from '@lavka/ui-kit';
import React from 'react';

interface Props {
    className?: string;
    size?: number;
}

export function IconCheck({className, size = 24}: Props) {
    return (
        <Svg className={className} size={size} viewBox="0 0 24 24">
            <path
                fill="currentColor"
                // eslint-disable-next-line max-len
                d="m10.165 15.18 8.358-8.357a1 1 0 0 1 1.414 0l.356.356a1 1 0 0 1 0 1.414L10.165 18.721l-5.458-5.459a1 1 0 0 1 0-1.414l.356-.356a1 1 0 0 1 1.414 0l3.688 3.689Z"
            />
        </Svg>
    );
}
