import {createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';

import type {CreateNotificationData, NotificationData} from 'client/scenes/notifications/types';

interface NotificationsState {
    items: NotificationData[];
}

const initialState: NotificationsState = {
    items: []
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        show: {
            reducer: (state, action: PayloadAction<NotificationData>) => {
                state.items.unshift(action.payload);
            },
            prepare: (notification: CreateNotificationData) => ({
                payload: {
                    ...notification,
                    id: nanoid()
                }
            })
        },
        hide: (state, action: PayloadAction<string>) => {
            const itemIdx = state.items.findIndex((item) => item.id === action.payload);
            if (itemIdx === -1) {
                return;
            }

            state.items.splice(itemIdx, 1);
        }
    }
});

export const {actions, reducer} = notificationsSlice;

export {actions as notificationsActions, reducer as notificationsReducer};
