import 'client/styles/index.global.css';

import {ConnectedRouter} from 'connected-react-router';
import React from 'react';

import type {UILanguage} from '@/src/i18n';
import {queryClient} from 'client/lib/query-client';
import {App} from 'client/scenes/app';
import {BaseAppWrapper} from 'client/scenes/base-app-wrapper';
import {history, store} from 'client/store';

interface AppWrapperProps {
    initLang: UILanguage;
}

export const AppWrapper: React.FC<AppWrapperProps> = ({initLang}) => (
    <BaseAppWrapper store={store} queryClient={queryClient} initLang={initLang}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </BaseAppWrapper>
);
