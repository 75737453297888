export enum Role {
    ADMIN = 'admin',
    USER = 'user',
    COURIER_VIEWER = 'courier_viewer'
}

export interface RoleNode {
    id: number;
    slugPath: string;
    updatedAt: Date;
}

export interface RoleNodePermission {
    id: number;
    roleNodeId: number;
    permissionSlugPath: string;
}

export interface RoleNodeTranslation {
    id: number;
    roleNodeId: number;
    languageCode: string;
    name?: string | null;
    help?: string | null;
}
