import type {MakeApiUrlOptions} from '@lavka-birds/client';
import qs from 'qs';

export function makeCompanyApiUrl({apiVersion, path, params}: MakeApiUrlOptions) {
    const queryParams = qs.stringify(params, {addQueryPrefix: true});
    const companyId = window ? window.location.pathname.split('/')[1] : '';
    return '/' + ['api', apiVersion, companyId, path].join('/') + queryParams;
}

export function makeApiUrl({apiVersion, path, params}: MakeApiUrlOptions) {
    const queryParams = qs.stringify(params, {addQueryPrefix: true});
    return '/' + ['api', apiVersion, path].join('/') + queryParams;
}
