import {combineReducers} from '@reduxjs/toolkit';

import {selectCompanyPageReducer} from 'client/pages/select-company-page/select-company-page-reducer';
import {notificationsReducer} from 'client/scenes/notifications/notifications-reducer';
import {usersImportReducer} from 'client/scenes/users/users-import-controller/users-import-reducer';
import {usersImportPreviewReducer} from 'client/scenes/users/users-import-preview/users-import-preview-reducer';
import {usersListReducer} from 'client/scenes/users/users-list/users-list-reducer';

export const scenesReducer = combineReducers({
    notifications: notificationsReducer,
    usersImportPreview: usersImportPreviewReducer,
    usersList: usersListReducer,
    selectCompanyPage: selectCompanyPageReducer,
    usersImport: usersImportReducer
});
