export type FeaturesConfigGeneric<TFeature extends string> = Partial<Record<TFeature, boolean>>;

export function serializeFeatures<TFeature extends string>(
    featuresConfig: FeaturesConfigGeneric<TFeature>,
    allowedFeatures?: TFeature[]
) {
    return Object.entries(featuresConfig)
        .filter(([featureName]) => (allowedFeatures ? allowedFeatures.includes(featureName as TFeature) : true))
        .map(([featureName, value]) => (value ? featureName : `${featureName}:false`))
        .sort((f1, f2) => (f1 > f2 ? 1 : -1))
        .join(',');
}

export function parseFeatures<TFeature extends string>(
    featuresString: string,
    allowedFeaturesSet: Set<TFeature>
): FeaturesConfigGeneric<TFeature> {
    const featuresArray = featuresString.split(',');
    return featuresArray.reduce((features, featureState) => {
        const [feature, falseValue] = featureState.split(':') as [TFeature, 'false' | undefined];
        if (allowedFeaturesSet.has(feature)) {
            features[feature] = falseValue === 'false' ? false : true;
        }
        return features;
    }, {} as FeaturesConfigGeneric<TFeature>);
}
