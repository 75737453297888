import * as reduxToolkit from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import type {History} from 'history';

import {scenesReducer} from 'client/scenes';

import {appReducer} from './app';

export const configureStore = (history: History, preloadedState?: object) => {
    const store = reduxToolkit.configureStore({
        preloadedState,
        middleware: [routerMiddleware(history)],
        reducer: {
            router: connectRouter(history),
            app: appReducer,
            scenes: scenesReducer
        }
    });

    return store;
};

export type Store = ReturnType<typeof configureStore>;
export type RootState = ReturnType<Store['getState']>;
export type PartialRootState = DeepPartial<RootState>;
