import {IdentityBasketUserKind, ROOT_COMPANY_SLUG} from '@lavka-birds/b2b-common';
import qs from 'qs';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {useCompanySlugStrict} from 'client/hooks/use-company-slug-strict';
import {
    generatePathPoliciesListView,
    generatePathPolicyView,
    generatePathUserRolesList,
    generatePathUsersList,
    generatePathUserView
} from 'client/pages/paths';
import type {PolicyTab} from 'client/scenes/roles/policy-form';
import {selectCompanySlug, selectUser} from 'client/store/app';

import {selectUserKindParameter} from './router-selectors';

interface UserPagePathParams {
    userId: string;
    companySlug: string;
    userKind?: IdentityBasketUserKind;
}

const generateUserPagePath = ({userId, companySlug, userKind}: UserPagePathParams) => {
    const search = qs.stringify({user_kind: userKind}, {addQueryPrefix: true});

    return (
        generatePathUserView({
            partnerOrCompanyId: companySlug,
            userId
        }) + search
    );
};

export function useGetUserViewPagePath() {
    const userKind = useSelector(selectUserKindParameter);
    const companySlug = useCompanySlugStrict();

    return useCallback(
        (userId: string) => generateUserPagePath({userId, companySlug, userKind}),
        [companySlug, userKind]
    );
}

export function useGetUsersListPagePath() {
    const userKind = useSelector(selectUserKindParameter);
    const companySlug = useCompanySlugStrict();

    return useCallback(() => {
        const search = qs.stringify({user_kind: userKind}, {addQueryPrefix: true});

        return generatePathUsersList({partnerOrCompanyId: companySlug}) + search;
    }, [companySlug, userKind]);
}

export function useGetUserRolesListPagePath() {
    const companySlug = useCompanySlugStrict();

    return useCallback(() => generatePathUserRolesList({partnerOrCompanyId: companySlug}), [companySlug]);
}

export function useGetPolicyViewPagePath() {
    const companySlug = useCompanySlugStrict();

    return useCallback(
        (policyId: string, policyTab?: PolicyTab) => {
            return generatePathPolicyView({policyId, partnerOrCompanyId: companySlug, policyTab});
        },
        [companySlug]
    );
}

export function useGetPoliciesListPagePath() {
    const companySlug = useCompanySlugStrict();

    return useCallback(() => {
        return generatePathPoliciesListView({partnerOrCompanyId: companySlug});
    }, [companySlug]);
}

export function useGoBack(returnUrl?: string): (() => void) | undefined {
    const history = useHistory();

    const goBack = useCallback(() => {
        if (returnUrl) {
            history.push(returnUrl);
        } else {
            history.goBack();
        }
    }, [returnUrl, history]);

    if (history.length > 1 || returnUrl) {
        return goBack;
    }
}

export function useCurrentUserPagePath(): string | undefined {
    const user = useSelector(selectUser);
    const companySlug = useSelector(selectCompanySlug);

    if (!user) {
        return;
    }

    if (user.kind === IdentityBasketUserKind.GLOBAL) {
        return generateUserPagePath({userId: user.id, companySlug: ROOT_COMPANY_SLUG});
    }

    if (!companySlug) {
        return;
    }

    return generateUserPagePath({userId: user.id, companySlug});
}
