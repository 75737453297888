import {block} from '@lavka-birds/client/dist/lib/bem/bem';
import React from 'react';

import styles from './icon-factory.css';

interface IconFactoryProps {
    className?: string;

    /** Нужно ли отзеркаливать иконку для RTL языков */
    rtlSupported?: boolean;

    children: React.ReactNode;
}

const IconFactoryComponent: React.FC<IconFactoryProps> = ({className, rtlSupported, children}) => {
    const cn = block(styles, 'icon-factory', className);

    return <span className={cn({rtlSupported})}>{children}</span>;
};

export const IconFactory = React.memo(IconFactoryComponent);
